import { AnswerType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import { GenericData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import extract from '~/params/extract.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageAnswersUpdate: FC<PageProps & EspaceProps & UserProps> = props => {
  const {
    espace,
    user,
    params: { espaceId, answerId },
  } = props;

  const model = new GenericData<AnswerType>({
    collectionName: 'answers',
    espaceId: espace.id,
    params: extract,
  });

  return (
    <TemplateEspace
      collectionName="answers"
      docId={answerId}
      espace={espace}
      user={user}
    >
      <CMSView>
        <Form
          docId={answerId}
          itemPathnamePrefix={`/espaces/${espaceId}/questions/answers/`}
          model={model}
          name="answer"
          type="update"
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageAnswersUpdate);
